import { News } from 'models';
import { newsDB, newsStorage, RBase } from '.';

export const getNewsList = async (lastNews?: News): Promise<News[]> => {
    const news = lastNews ?
        await newsDB.orderBy('createAt', "asc").startAt(lastNews).limit(6).get() :
        await newsDB.orderBy('createAt', "asc").limit(6).get();
    return news.docs.map(doc => ({ ...doc.data(), __id: doc.id }));
};

export const getNews = async (id: string): Promise<News | null> => {
    const news = (await newsDB.doc().get()).data();
    if (news) return { ...news, __id: id };
    return null;
};

export const getNewsCount = async (): Promise<number> =>
    (await RBase.ref('news/count').get()).val();

export const getNewsPreview = async (news: News[]) =>
    Promise.all<News>(news.map((news) =>
        newsStorage
            .child(news.preview)
            .getDownloadURL()
            .then((preview) => ({
                ...news,
                preview
            }))
    ));