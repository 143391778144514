import React, { memo, useState } from "react";
import Logo from "images/LOGO.svg";
import { pages } from "pages";
import { Link } from "react-router-dom";
import { ReactComponent as Icon } from "./menu.svg";
import { ReactComponent as Close } from "./close.svg";
import cn from "classnames";
import * as R from "ramda";
import styles from "./style.module.scss";
import config from "config/index.json";
import "./style.scss";
import { logEvent } from "fb";

const phoneClick = () => {
  logEvent("phone_call");
};

export default memo(function Header() {
  const [visibleRightMenu, setVisible] = useState(false);

  return (
    <>
      <header
        style={{ backgroundImage: `url(${Logo})` }}
        className={styles.header}
      >
        <button
          type="button"
          className={styles.menuButton}
          onClick={() => setVisible(true)}
        >
          <Icon />
        </button>
        <div>
          <nav className={styles.headerNavigation}>
            <ul>
              {pages.filter(R.prop("name")).map((page, index) => (
                <li key={index}>
                  <Link to={page.path} title={page.path}>
                    {page.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <div className={styles.headerTelephone}>
            <span>
              {config.postalCode} {config.address}
            </span>
            <a href={`telephone:${config.telephone}`} onClick={phoneClick}>
              {config.telephoneVisible}
            </a>
          </div>
        </div>
      </header>
      <div className={styles.headerTelephoneBottom}>
        <span>
          {config.postalCode} {config.address}
        </span>
        <br />
        <a href={`telephone:${config.telephone}`} onClick={phoneClick}>
          {config.telephoneVisible}
        </a>
      </div>
      <div
        className={cn(
          styles.backgroundAside,
          visibleRightMenu && styles.backgroundAsideOpen
        )}
        onClick={() => setVisible(false)}
      />

      <aside className={cn(styles.aside, visibleRightMenu && styles.asideOpen)}>
        <div className={styles.asideClose}>
          <button
            type="button"
            className={styles.closeButton}
            onClick={() => setVisible(false)}
          >
            <Close />
          </button>
        </div>
        <nav>
          <ul>
            {pages.filter(R.prop("name")).map((page, index) => (
              <li key={index}>
                <Link
                  to={page.path}
                  title={page.path}
                  onClick={() => setVisible(false)}
                >
                  {page.name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </aside>
    </>
  );
});
