import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Epic, ofType } from "redux-observable";
import { interval } from "rxjs";
import { switchMap, map } from "rxjs/operators";
import { addEpics } from "./epicsBus";

export const initialState: {
    index: number,
    listUrls: string[],
    loading: boolean
} = {
    index: 0,
    listUrls: [],
    loading: false,
}
const sliderStore = createSlice({
    name: 'slider',
    initialState,
    reducers: {
        init(state) {
            state.listUrls = [];
            state.loading = true;
        },
        initSuccess(state, { payload }: PayloadAction<string[]>) {
            state.listUrls = payload;
            state.loading = false;
        },
        next(state) {
            state.index = state.index + 1 === state.listUrls.length ? 0 : state.index + 1;
        },
        previous(state) {
            state.index = state.index - 1 === 0 ? state.listUrls.length - 1 : state.index - 1;
        },
    }
});

const setIntervalUrl: Epic = ($action) =>
    $action.pipe(
        ofType(
            sliderStore.actions.initSuccess.type, 
            sliderStore.actions.next.type, 
            sliderStore.actions.previous.type
        ),
        switchMap(() => interval(10000)),
        map(() => sliderStore.actions.next())
    )
export const rootEpic = [setIntervalUrl];
addEpics(rootEpic);
export default sliderStore;