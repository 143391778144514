import { Action, AnyAction, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { getEpicsForMiddleware } from "./epicsBus";
import newsStore, { initialState as newsState } from './news';
import productsStore, { initialState as productsState } from "stores/products";
import { createEpicMiddleware, Epic } from 'redux-observable';
import sliderStore, { initialState as sliderInitState } from 'stores/slider';


const reducer = {
  product: productsStore.reducer,
  news: newsStore.reducer,
  slider: sliderStore.reducer,
};

const preloadedState = {
  product: productsState,
  news: newsState,
  slider: sliderInitState,
};

const epicMiddleWare = createEpicMiddleware<Action<any>, Action<any>, typeof preloadedState, any>();
const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
    thunk: true,
  }),
  epicMiddleWare,
];
const store = configureStore({
  reducer,
  middleware,
  preloadedState,
  devTools: process.env.NODE_ENV === "development",
});

epicMiddleWare.run(getEpicsForMiddleware());

export type GState = typeof preloadedState;
export type GEpic = Epic<AnyAction, any, GState, {}>;

export default store;