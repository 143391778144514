import React, { useCallback } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import cn from "classnames";
import { ReactComponent as IconLi } from "./icon.svg";
import { GState } from "stores";
import "./style.scss";
import { logEvent } from "fb";

const selector = ({ product: { schema, list } }: GState) => ({
  products: schema,
  articles: list,
});
export default function OurProducts() {
  const { products, articles } = useSelector(selector, shallowEqual);
  const routeNameMatch = useRouteMatch<{ name: string; id: string }>(
    "/products/:name"
  );

  const openProducts = useCallback<React.MouseEventHandler<HTMLAnchorElement>>((event) => {
    logEvent('open_products');
  },[]);
  
  return (
    <div className="our-products-aside">
      <div>
        <div className="our-products-aside-header">Наши продукты</div>
        <ul className="our-products-aside-list">
          {products.map((product, index) => {
            return (
              <li key={index}>
                <IconLi fill={product.color} />
                <Link to={`/products/${product.name}`} onClick={openProducts}>
                  {product.name}
                </Link>
                {routeNameMatch?.params?.name &&
                routeNameMatch?.params?.name?.toLocaleLowerCase() ===
                  product?.name?.toLocaleLowerCase() ? (
                  <ul
                    style={{
                      maxHeight: product.items.length * 20 + 20,
                      paddingTop: 30,
                    }}
                  >
                    {product.items.map((product_index, index) => {
                      return (
                        <li key={index}>
                          {typeof product_index === "number" ? (
                            <Link
                              onClick={openProducts}
                              to={`/products/${product.name}/${articles[product_index]?.article}`}
                              className={cn(
                                routeNameMatch.params.id ===
                                  articles[
                                    product_index
                                  ]?.article?.toString() && "active"
                              )}
                            >
                              {articles[product_index]?.article}{" "}
                              {articles[product_index]?.name}
                            </Link>
                          ) : (
                            <Link
                              to={`/products/${product.name}/${product_index.name}`}
                            >
                              {product_index.name}
                            </Link>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <ul />
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
