import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { GState } from "stores";
import sliderStore from "stores/slider";
import Loading from "components/loading";
import { ReactComponent as IconArrrow } from "./arrow.svg";
import { useTransition, animated } from "react-spring";
import "./style.scss";
import { storage } from "fb";

const selector = ({ slider: { loading, index, listUrls } }: GState) => ({
  img: listUrls[index],
  loading,
  index,
});

export default function SliderMain() {
  const {index, img, loading } = useSelector(selector, shallowEqual);
  const [url, setUrl] = useState('');
  const previousIndex = useRef(index);
  const dispatch = useDispatch();

  useEffect(() => {
    setUrl('');
    if (!img) return;
    storage.ref(`articles/${img}`).getDownloadURL().then(setUrl);
  }, [img]);

  const nextClick = useCallback(() => dispatch(sliderStore.actions.next()), [
    dispatch,
  ]);
  const previousClick = useCallback(
    () => dispatch(sliderStore.actions.previous()),
    [dispatch]
  );

  const isBack = previousIndex.current > index;
  const transitions = useTransition(url, null, {
    from: () => ({ transform: `translateX(${isBack ? '-': ''}100%)` }),
    enter: { transform: "translateX(0%)" },
    leave: () => ({ transform: `translateX(${isBack ? '': '-'}100%)` }),
  });
  previousIndex.current = index;
  return (
    <div className="slider-main">
      {loading ? (
        <Loading />
      ) : (
        <>
          {transitions.map(({ item,key, props: style }) => (
            <animated.div
              className="slider-main-slide"
              style={{ ...style, backgroundImage: `url(${item})` }}
              key={key}
            />
          ))}

          <button className="slider-main-btn previous" onClick={previousClick}>
            <IconArrrow />
          </button>
          <button className="slider-main-btn next" onClick={nextClick}>
            <IconArrrow />
          </button>
        </>
      )}
    </div>
  );
}
