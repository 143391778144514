import { AxiosRequestConfig } from 'axios';
import allProducts from 'data/articles.json';
import schema from 'data/schema.json';
import { product, productMainGroup } from 'models';


export const getAllProducts = (config?: AxiosRequestConfig) => Promise.resolve<{data: product[]}>({data: allProducts as any});

export const getProduct = (id: number, config?: AxiosRequestConfig) => Promise.resolve<{data: product}>({data: allProducts[id] as any});

export const getSchema = (config?: AxiosRequestConfig) => Promise.resolve<{data:productMainGroup[]}>({data: schema as any});