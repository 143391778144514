//@ts-nocheck
import React, { useEffect } from 'react';

const Counters = [92334337];

const Init = () => {
    (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
    m[i].l=1*new Date();
    for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-sequences
    k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
    (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

    Counters.forEach(counter => ym(counter, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true
   }))
};

export const LogEvent = (event: string, data: {[key in string]: any}) => {
    // Yandex счетчик
    if (window.ym)
     Counters.forEach(counter => window.ym(counter, event, target));
};


export default function Metrika () {

    useEffect(Init, []);
    return (
        <>
            {/* <!-- Yandex.Metrika counter --> */}
            <noscript><div><img src="https://mc.yandex.ru/watch/92334337" style={{position: 'absolute', left:-9999}} alt="" /></div></noscript>
            {/* <!-- /Yandex.Metrika counter --> */}
        </>
    )
}