import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/analytics';
import { News } from 'models';

import config from './config.json';
const app = firebase.initializeApp(config);

const newsConvertion:firebase.firestore.FirestoreDataConverter<News>  = {
    toFirestore(data: any): firebase.firestore.DocumentData{
      return data
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions
    ):News{
      const data = snapshot.data(options) as News;
      return data
    }
  }

const isProduction = process.env.NODE_ENV === 'production';

export const cloudDB = app.firestore();
export const storage = app.storage();
export const RBase = app.database();
export const logEvent = isProduction ? app.analytics().logEvent : () => {};
export const newsDB = cloudDB.collection('news/').withConverter(newsConvertion);
export const newsStorage = storage.ref('news/');
export default app;