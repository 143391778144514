import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getNewsCount, getNewsList, getNewsPreview } from "fb/news";
import { News } from "models";
import { of } from "ramda";
import { ofType } from "redux-observable";
import { catchError, filter, ignoreElements, map, switchMap } from "rxjs/operators";
import { GEpic } from "stores";
import { addEpics } from "stores/epicsBus";
import { createLinkFromNews } from "./utils";

export const initialState: {
    list: string[],
    page: number,
    totalPage: number,
    error: boolean,
    mapLinkId: {[key in string]: string},
    cachePages: {[key in number]: string};
    cache: {[key in string]: News};
    loading: boolean,
} = {
    list: [],
    loading: false,
    error: false,
    page: 1,
    mapLinkId: {},
    totalPage: 1,
    cache: {},
    cachePages: {},
}

const newsSlice = createSlice({
    name: "news",
    initialState,
    reducers: {
        initNews(state) {
            state.loading = true;
        },
        initNewsItem(state, {payload}) {},
        initNewsItemSuccess(state, {payload}: PayloadAction<News>){
            state.cache[payload.__id] = payload;
            state.mapLinkId[createLinkFromNews(payload)] = payload.__id;
        },
        updateTotalCount (state, {payload}: PayloadAction<number>) {
            state.totalPage = payload;
        },
        loadNewsSuccess(state, { payload }: PayloadAction<News[]>) {
            state.loading = false;
            state.list = payload.map(news => {
                state.cache[news.__id] = news;
                state.mapLinkId[createLinkFromNews(news)] = news.__id;
                return news.__id;
            });
        },
        loadNewsFailed(state, { payload }){
            state.error = true;
            state.loading = false;
        },
        getError(state, { payload }) {
            state.error = true;
        },
        changePage(state, {payload}: PayloadAction<number>) {
            state.loading = true;
            state.error = false;
            state.page = Math.max(1,Math.min(payload, state.totalPage));
        }
    }
});

// export const initNewsItem: GEpic = (action$, store$) => action$.pipe(
//     ofType(newsSlice.actions.initNewsItem.type),
//     switchMap(async ({payload}) => )
// )

export const initLoad: GEpic = (action$, store$) => action$.pipe(
    ofType(newsSlice.actions.initNews.type),
    filter(() => !store$.value.news.list.length),
    switchMap(async () => await getNewsList()),
    switchMap(getNewsPreview),
    map(newsSlice.actions.loadNewsSuccess),
    catchError(err => of(newsSlice.actions.loadNewsFailed(err)))
);

export const changePage: GEpic = (action$, store$) => action$.pipe(
    ofType(newsSlice.actions.changePage.type),
    filter(() => !store$.value.news.list.length),
    switchMap(async () => await getNewsList()),
    switchMap(getNewsPreview),
    map(newsSlice.actions.loadNewsSuccess),
    catchError(err => of(newsSlice.actions.loadNewsFailed(err)))
);

export const initGetCount: GEpic = (action$) => action$.pipe(
    ofType(newsSlice.actions.initNews.type),
    switchMap(() => getNewsCount()),
    map(newsSlice.actions.updateTotalCount),
    catchError(err => of(newsSlice.actions.getError(err)))
)

export const LoadPageEpic: GEpic = (action$) => 
    action$.pipe(
        ofType(newsSlice.actions.changePage.type),
        ignoreElements(),
    );

const epics = [LoadPageEpic, initLoad, initGetCount, changePage];
addEpics(epics);
export default newsSlice