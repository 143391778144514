import React, { FunctionComponent, lazy, Suspense } from "react";
import { Switch, Route } from "react-router";
type Page = {
  name: string;
  path: string;
  component: FunctionComponent;
};
const pages: Page[] = [];
const addPage = (name: string, path: string, component: FunctionComponent) =>
  pages.push({
    name,
    path,
    component,
  });
const page404 = lazy(() => import("./404"));
addPage(
  "Главная",
  "/",
  lazy(() => import("./home"))
);
addPage(
  "Продукты и услуги",
  "/products",
  lazy(() => import("./product"))
);
addPage(
  "Каталог",
  "/Catalog",
  lazy(() => import("./catalog"))
);
addPage(
  "Квалификация и сертификаты",
  "/qualityAndCertification",
  lazy(() => import("./qualityAndCertification"))
);
addPage(
  "Контакты",
  "/contacts",
  lazy(() => import("./contacts"))
);
addPage(
  "Новости",
  "/news",
  lazy(() => import("./news"))
);
addPage(
  "",
  "/products/:name",
  lazy(() => import("./product"))
);
addPage(
  "",
  "/products/:name/:article",
  lazy(() => import("./product_item"))
);
addPage("", "/news/:id", lazy(() => import("./news_item")));
addPage("", "/*", page404);
addPage("", "/noteFound", page404);
addPage("", "/404", page404);
export { pages };
export default function () {
  return (
    <Suspense fallback={<div />}>
      <Switch>
        {pages.map((page) => (
          <Route
            key={page.path}
            path={page.path}
            exact
            component={page.component}
          />
        ))}
      </Switch>
    </Suspense>
  );
}
