import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { product, productMainGroup } from "models";
import './epic';

export const initialState: {
    list: product[],
    schema: productMainGroup[],
} = {
    list: [],
    schema: [],
}

const Products = createSlice({
    initialState,
    name: 'article',
    reducers: {
        init(state) {

        },
        initSuccess(state, { payload }: PayloadAction<product[]>) {
            state.list = payload;
        },
        schemaInit(state){
            state.schema = [];
        },
        schemaInitSuccess(state, {payload}: PayloadAction<productMainGroup[]>) {
            state.schema = payload;
        }
    }
});

export default Products;