import React, {  useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import Pages from "./pages";
import { useRouteMatch } from "react-router";
import cn from "classnames";
import { useDispatch, batch } from "react-redux";
import productsStore from "stores/products";
import OurProducts from "components/ourProducts";
import SliderMain from "components/slider-main";
import "./app.scss";

export default function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    batch(() => {
      dispatch(productsStore.actions.init());
      dispatch(productsStore.actions.schemaInit());
    });
  }, [dispatch]);

  const routeNameMatch = useRouteMatch<{ name: string }>("/products/:name");
  const isShowSlider = !routeNameMatch;

  return (
    <>
      <Header />
      <div
        className={cn("content", !isShowSlider && "no-slider")}
      >
        {isShowSlider && <SliderMain />}
        <OurProducts />
        <div className="page">
          <Pages />
        </div>
      </div>
      <Footer />
    </>
  );
}
