import { mergeMap, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import productsStore from '.';
import { from } from 'rxjs';
import { getAllProducts, getSchema } from 'api/products';
import sliderStore from 'stores/slider';
import { GEpic } from 'stores';
import { addEpics } from 'stores/epicsBus';

export const getProducts: GEpic = ($action) =>
    $action.pipe(
        ofType(productsStore.actions.init.type),
        mergeMap(() => from(getAllProducts())),
        map(response => productsStore.actions.initSuccess(response.data))
    )

export const initSchema: GEpic = ($action) =>
    $action.pipe(
        ofType(productsStore.actions.schemaInit.type),
        mergeMap(() => from(getSchema())),
        map(response => productsStore.actions.schemaInitSuccess(response.data))
    )

export const updateSlider: GEpic = ($action) => 
$action.pipe(
    ofType(productsStore.actions.initSuccess.type),
    map(action => action.payload.filter((product: any) => product.preview_img)),
    map(products => products.map((product: any) => product.preview_img?.url)),
    map(urls => sliderStore.actions.initSuccess(urls))
)

export const loadPage: GEpic = (action$, store$) => 
    action$.pipe();

const rootEpics = [getProducts, initSchema,updateSlider]
addEpics(rootEpics);
export default {};